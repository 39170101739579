.default-class{
    border-radius: 5px !important;
    border: 5px solid #000;
    background-color: #653b13;
    width: 75px;
    height: 75px;
    opacity: 1 !important;
}
.form-check-info .form-check-input:checked{
    background-color: #E46C0A;
    border-color: #797B7B;
    background-image: none;
}
.correct-answer{
    opacity: 1 !important;
    border-color: #000 !important;
    background-color: #00d5b8 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")!important;
}
.incorrect-answer{
    opacity: 1 !important;
    background-color: #f32020 !important;
    border-color: #000 !important;
    background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='9.39844' y='20.7144' width='16' height='3.66667' rx='1.33333' transform='rotate(-45 9.39844 20.7144)' fill='white'/%3E%3Crect x='11.2852' y='9.40039' width='16' height='3.66667' rx='1.33333' transform='rotate(45 11.2852 9.40039)' fill='white'/%3E%3C/svg%3E") !important;
}

.question-image {
    vertical-align: top;
    transform-origin: top;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.question-image:hover {
    transform: scale(2);
}

.content-en .option-question-image {
    vertical-align: top;
    transform-origin: left;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    margin-left: 15px;
}

.content-en .option-question-image:hover {
    transform: scale(2);
}

.content-ae .option-question-image, .content-ur .option-question-image {
    vertical-align: top;
    transform-origin: right;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.content-ae .option-question-image:hover, .content-ur .option-question-image:hover {
    transform: scale(2);
}

.question-image-container{
    vertical-align: top;
    transform-origin: top;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

#timer2 .countdownlist{
    gap: 10px;
}
#timer2 .countdownlist .countdownlist-item{
    width: 33.3%;
}
#timer2 .countdownlist .count-num{
    background: none;
    color: #fff;
    box-shadow: none;
    border: none;
    font-weight: normal;
    font-size: 18px;
    padding: 5px;
}
#timer2 .countdownlist .count-num::after {
    content: ":";
    font-size: 20px;
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
}

.zoom-effect {
    overflow: hidden;
    position: relative;
  }

  .zoom-effect img {
    transition: transform 0.3s ease;
  }

  .zoom-effect:hover img {
    transform: scale(1.2);
  }

  /* CSS to remove spinners from input type="number" */
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield; /* Firefox */
}



.question-image-sharja {
    vertical-align: top;
    transform-origin: top;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.question-image-sharja:hover {
    transform: scale(2);
}
